import {homeConstants, productConstants} from "../constants";
import axiosConfig from '../axiosConfig'

const websiteId  = 1;

export const getMaincategoryTreeview = () => async (dispatch) => {

    try {
        const res = await axiosConfig.get(`/websites/`+websiteId+`/websitecategories/treeview`);
        dispatch({
            type: homeConstants.MAIN_CATEGORY_TREE,
            payload: res.data,
        });
    } catch (e) {
        dispatch({
            type: homeConstants.MAIN_CATEGORY_TREE_ERROR,
            payload: console.log(e),
        });
    }
};

export const getHomeBanner = () => async (dispatch) => {

    try {
        const res = await axiosConfig.get(`/websites/`+websiteId+`/websitebanners?filter[active]=true&per_page=9999&filter[type]=Slider Banner,Right Banner,Left Banner`);
        dispatch({
            type: homeConstants.BANNER,
            payload: res,
        });
    } catch (e) {
        dispatch({
            type: homeConstants.BANNER_ERROR,
            payload: console.log(e),
        });
    }
};

export const getHomeBrands = (per_page = 99999) => async (dispatch) => {

    try {
        const res = await axiosConfig.get(`/websites/`+websiteId+`/websitemanufacturers?filter[slider]=1&filter[active]=1&per_page=${per_page}`);
        dispatch({
            type: homeConstants.BRANDS,
            payload: res,
        });
    } catch (e) {
        dispatch({
            type: homeConstants.BRANDS_ERROR,
            payload: console.log(e),
        });
    }
};

export const getHomeNavbar = () => async (dispatch) => {

    try {
        const res = await axiosConfig.get(`/websites/`+websiteId+`/websiteheadermenus/treeview`);
        dispatch({
            type: homeConstants.NAVBAR_HEADER,
            payload: res.data,
        });
    } catch (e) {
        dispatch({
            type: homeConstants.NAVBAR_HEADER_ERROR,
            payload: console.log(e),
        });
    }
};

export const getHomeNavbarDetail = (url) => async (dispatch) => {

    try {
        const res = await axiosConfig.get(`/websites/${websiteId}/websiteheadermenus/detail?url=${url}`);
        dispatch({
            type: homeConstants.NAVBAR_HEADER_DETAIL,
            payload: res,
        });
    } catch (e) {
        dispatch({
            type: homeConstants.NAVBAR_HEADER_DETAIL_ERROR,
            payload: e.response.data ,
        });
    }
};

export const getHomeMainCategoryDetail = (filterUrl) => async (dispatch) => {

    try {
        const res = await axiosConfig.get(`/websites/${websiteId}/websitecategories/detail?url=${filterUrl}`);
        dispatch({
            type: homeConstants.MAIN_CATEGORY_DETAIL_HEADER,
            payload: res, // Assuming the response data is what you want to dispatch
        });

    } catch (e) {
        dispatch({
            type: homeConstants.MAIN_CATEGORY_DETAIL_HEADER_ERROR,
            payload:e.response.data,
        });
    }
};

export const getHomeMainManufacturerDetail = (filterUrl) => async (dispatch) => {

    try {
        const res = await axiosConfig.get(`/websites/${websiteId}/websitemanufacturers/detail?url=${filterUrl}`);
        dispatch({
            type: homeConstants.MAIN_MANUFACTURES_DETAIL_HEADER,
            payload: res,
        });
    } catch (e) {
        dispatch({
            type: homeConstants.MAIN_MANUFACTURES_DETAIL_HEADER_ERROR,
            payload: console.log(e),
        });
    }
};
export const getHomeManufacturerListing = (filterUrl) => async (dispatch) => {

    try {
        const res = await axiosConfig.get(`/websites/${websiteId}/websitemanufacturers?filter[url]=${filterUrl}`);
        dispatch({
            type: homeConstants.MANUFACTURE_LISTING,
            payload: res,
        });
    } catch (e) {
        dispatch({
            type: homeConstants.MANUFACTURE_LISTING_ERROR,
            payload: console.log(e),
        });
    }
};

export const getHomeTopManufacture = () => async (dispatch) => {

    try {
        const res = await axiosConfig.get(`/websites/`+websiteId+`/websitetopsellermanufacturers?per_page=12&sort=sequence`);
        dispatch({
            type: homeConstants.TOP_MANUFACTURE,
            payload: res,
        });
    } catch (e) {
        dispatch({
            type: homeConstants.TOP_MANUFACTURE_ERROR,
            payload: console.log(e),
        });
    }
};

export const getHomeTopSellerCategories = () => async (dispatch) => {

    try {
        const res = await axiosConfig.get(`/websites/`+websiteId+`/websitetopsellercategories?per_page=12&sort=sequence`);
        dispatch({
            type: homeConstants.TOP_CATEGORY,
            payload: res,
        });
    } catch (e) {
        dispatch({
            type: homeConstants.TOP_CATEGORY_ERROR,
            payload: console.log(e),
        });
    }
};

export const getFeaturedcategories = () => async (dispatch) => {

    try {
        const res = await axiosConfig.get(`/websites/`+websiteId+`/websitefeaturedcategories/treeview`);
        dispatch({
            type: homeConstants.FEATURE_CATEGORIES,
            payload: res,
        });
    } catch (e) {
        dispatch({
            type: homeConstants.FEATURE_CATEGORIES_ERROR,
            payload: console.log(e),
        });
    }
};
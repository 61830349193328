import React, { useState, useRef, useEffect } from 'react';
import ThinBag from "../../../Helpers/icons/ThinBag";
import Middlebar from "./Middlebar";
import Navbar from "./Navbar";
import TopBar from "./TopBar";
import NavbarSecond from "./NavbarSecond";
import { useShoppingCart } from "use-shopping-cart";

export default function HeaderOne({ className, drawerAction, type = 1 }) {
  const { cartCount } = useShoppingCart();
  const [isSticky, setIsSticky] = useState(false);
  const headerRef = useRef(null);

  const handleScroll = () => {
    if (headerRef.current) {
      const header = headerRef.current.getBoundingClientRect();
      if (window.pageYOffset > header.top) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <header ref={headerRef} className={`${className || ""} header-section-wrapper ${isSticky ? 'sticky' : ''}`}>
      <TopBar isSticky={isSticky} className="quomodo-shop-top-bar" />
      <Middlebar isSticky={isSticky} type={type} className="quomodo-shop-middle-bar lg:block hidden" />
      <div className="quomodo-shop-drawer lg:hidden block w-full h-[60px] bg-white">
        <div className="w-full h-full flex justify-between items-center px-5">
          <div onClick={drawerAction}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4 6h16M4 12h16M4 18h7"
              />
            </svg>
          </div>
          <div>
            {type === 3 ? (
              <a href="/">
                <img
                  width="152"
                  height="36"
                  src={`${process.env.PUBLIC_URL}/assets/images/logo-3.svg`}
                  alt="logo"
                />
              </a>
            ) : type === 4 ? (
              <a href="/">
                <img
                  width="152"
                  height="36"
                  src={`${process.env.PUBLIC_URL}/assets/images/logo-4.svg`}
                  alt="logo"
                />
              </a>
            ) : (
              <a href="/">
                <img
                  width="152"
                  height="36"
                  src={`${process.env.PUBLIC_URL}/assets/images/ETechBuy-Logo.svg`}
                  alt="logo"
                />
              </a>
            )}
          </div>
          <div className="cart relative cursor-pointer">
            <a href="/cart">
              <span>
                <ThinBag />
              </span>
            </a>
            <span className={`w-[18px] h-[18px] rounded-full absolute -top-2.5 -right-2.5 flex justify-center items-center text-[9px] ${type === 3 ? 'bg-qh3-blue text-white' : 'bg-qyellow text-qblack'}`}>
              {cartCount}
            </span>
          </div>
        </div>
      </div>
      {!isSticky && <Navbar type={type} className="quomodo-shop-nav-bar lg:block hidden" />}
      <NavbarSecond type={type} className="quomodo-shop-nav-bar lg:block hidden" />
    </header>
  );
}

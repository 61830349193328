import { Link } from "react-router-dom";
import {getProductFilters, getProducts} from "../../actions/productAction";
import {getHomeMainCategoryDetail, getHomeManufacturerListing} from "../../actions/homeAction";
import {connect} from "react-redux";


const ChildBrandCategories = (props) => {
  const { className,categoryData} = props;
  return (
    <div className={`w-full ${className || ""}`}>
      <div className="justify-center items-center flex mb-[60px]">
      {/* "grid xl:grid-cols-6 lg:grid-cols-5 sm:grid-cols-3 grid-cols-1 xl:gap-[30px] gap-5 */}
        {Array.isArray(categoryData) &&
        categoryData.map((category) => (
            <div
                data-aos="fade-left"
                data-aos-duration="500"
                className="item w-full flex flex-col items-center"
                key={category.id}
            >
              <div className="w-[170px] h-[170px] rounded-full bg-white flex justify-center items-center overflow-hidden mb-2">
                <img
                    src={`${category.image}`}
                    alt=""
                />
              </div>
              <Link to={'/category/'+category.url}>
                <p className="text-base font-500 text-center">{category.title}</p>
              </Link>
            </div>
        ))}
      </div>
    </div>
  );
}
const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(ChildBrandCategories);

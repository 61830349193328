import { useState ,useEffect } from "react";
import { useShoppingCart } from 'use-shopping-cart';

export default function InputQuantityCom(props) {
  const { originalQuantity ,productId} = props;
  const [quantity, setQuantity] = useState(originalQuantity);
  useEffect(() => {
    setQuantity(originalQuantity); // Update the local state when the originalQuantity prop changes
  }, [originalQuantity]);

  const { cartDetails, removeItem, incrementItem, decrementItem } = useShoppingCart();

  const increment = () => {
    setQuantity((prev) => prev + 1);
    incrementItem(productId);
  };
  const decrement = () => {
    if (quantity > 1) {
      setQuantity((prev) => prev - 1);
      decrementItem(productId);
    }
  };

  return (
    <div className="w-[120px] h-[40px] px-[26px] flex items-center border border-qgray-border">
      <div className="flex justify-between items-center w-full">
        <button
          onClick={decrement}
          type="button"
          className="text-base text-qgray"
        >
          -
        </button>
        <span className="text-qblack">{quantity}</span>
        <button
          onClick={increment}
          type="button"
          className="text-base text-qgray"
        >
          +
        </button>
      </div>
    </div>
  );
}

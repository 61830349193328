import { Link } from "react-router-dom";
import PageTitle from "../Helpers/PageTitle";
import Layout from "../Partials/Layout";
import {getPage} from "../../actions/commonAction";
import {connect} from "react-redux";
import {useEffect, useState} from "react";
import ReactHtmlParser from "react-html-parser";


const Orderprocess = (props) => {
    const [content, setContent] = useState("");

    useEffect(() => {
      // Fetch main category tree when the component mounts
      props.getPage("order-process");
    }, []);


  useEffect(() => {
    setContent(props.page_res?.data?.content)
  }, [props.page_res]);


  return (
    <Layout childrenClasses="pt-0 pb-0">
      <div className="about-page-wrapper w-full">
        <div className="title-area w-full">
          <PageTitle
            title="Order Process"
            breadcrumb={[
              { name: "home", path: "/" },
              { name: "Order Process", path: "/order-process" },
            ]}
          />
        </div>

        <div className="aboutus-wrapper w-full">
          <div className="container-x mx-auto">
            <div className="w-full min-h-[665px] lg:flex lg:space-x-12 items-center pb-10 lg:pb-0">
              <div className="content flex-1 mt-5">
                {ReactHtmlParser(content)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

const mapStateToProps = (state) => ({
  page_res: state.common.pageRes,
});

const mapDispatchToProps = (dispatch) => ({
  getPage: (slug) => dispatch(getPage(slug)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Orderprocess);

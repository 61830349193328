import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import datas from "../../../data/products.json";
import BreadcrumbCom from "../../BreadcrumbCom";
import Layout from "../../Partials/Layout";
import IcoAdress from "./icons/IcoAdress";
import IcoCart from "./icons/IcoCart";
import IcoDashboard from "./icons/IcoDashboard";
import IcoLogout from "./icons/IcoLogout";
import IcoLove from "./icons/IcoLove";
import IcoPassword from "./icons/IcoPassword";
import IcoPayment from "./icons/IcoPayment";
import IcoPeople from "./icons/IcoPeople";
import IcoReviewHand from "./icons/IcoReviewHand";
import IcoSupport from "./icons/IcoSupport";
import AddressesTab from "./tabs/AddressesTab";
import Dashboard from "./tabs/Dashboard";
import OrderTab from "./tabs/OrderTab";
import PasswordTab from "./tabs/PasswordTab";
import Payment from "./tabs/Payment";
import ProfileTab from "./tabs/ProfileTab";
import ReviewTab from "./tabs/ReviewTab";
import SupportTab from "./tabs/SupportTab";
import WishlistTab from "./tabs/WishlistTab";
import Quote from "./tabs/Quote";
import FeedBackForm from "./tabs/FeedBackForm";
import CancelTab from "./tabs/CancelTab";
import ReturnTab from "./tabs/ReturnTab";
import PendingTab from "./tabs/PendingTab";


export default function Profile() {
  const [switchDashboard, setSwitchDashboard] = useState(false);
  const location = useLocation();
  const getHashContent = location.hash.split("#");
  const [active, setActive] = useState("dashboard");
  const [userData, setUserData] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    setActive(
      getHashContent && getHashContent.length > 1
        ? getHashContent[1]
        : "dashboard"
    );
  }, [getHashContent]);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user_data'));
    setUserData(user);
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('user_data');
    setTimeout(() => {
      navigate('/login');
    }, 500);

  };


  return (
    <Layout childrenClasses="pt-0 pb-0">
      <div className="profile-page-wrapper w-full">
        <div className="container-x mx-auto">
          <div className="w-full my-10">
            <BreadcrumbCom
              paths={[
                { name: "home", path: "/" },
                { name: "profile", path: "/profile" },
              ]}
            />
            <div className="w-full bg-white px-10 py-9">
              <div className="title-area w-full flex justify-between items-center">
                <h1 className="text-[22px] font-bold text-qblack">
                  Your Dashboard
                </h1>
                {/*<div className="switch-dashboard flex space-x-3 items-center">*/}
                {/*  <p className="text-qgray text-base">Switch Dashboard</p>*/}
                {/*  <button*/}
                {/*    onClick={() => setSwitchDashboard(!switchDashboard)}*/}
                {/*    type="button"*/}
                {/*    className="w-[73px] h-[31px] border border-[#D9D9D9] rounded-full relative "*/}
                {/*  >*/}
                {/*    <div*/}
                {/*      className={`w-[23px] h-[23px] bg-qblack rounded-full absolute top-[3px] transition-all duration-300 ease-in-out ${*/}
                {/*        switchDashboard ? "left-[44px]" : "left-[4px]"*/}
                {/*      }`}*/}
                {/*    ></div>*/}
                {/*  </button>*/}
                {/*</div>*/}
              </div>
              <div className="profile-wrapper w-full mt-8  space-x-10">
                <div className="w-[236px] sideProfile">
                  <div className="flex flex-col space-y-10">
                    <div className="item group">
                      <Link to="/profile#dashboard">
                        <div className="flex space-x-3 items-center text-qgray hover:text-qblack">
                          <span>
                            <IcoDashboard />
                          </span>
                          <span className=" font-normal text-base">
                            Dashboard
                          </span>
                        </div>
                      </Link>
                    </div>
                    <div className="item group">
                      <Link to="/profile#profile">
                        <div className="flex space-x-3 items-center text-qgray hover:text-qblack">
                          <span>
                            <IcoPeople />
                          </span>
                          <span className=" font-normal text-base">
                            Personal Information
                          </span>
                        </div>
                      </Link>
                    </div>

                    {/*<div className="item group">*/}
                    {/*  <Link to="/profile#payment">*/}
                    {/*    <div className="flex space-x-3 items-center text-qgray hover:text-qblack">*/}
                    {/*      <span>*/}
                    {/*        <IcoPayment />*/}
                    {/*      </span>*/}
                    {/*      <span className=" font-normal text-base">*/}
                    {/*        Payment Method*/}
                    {/*      </span>*/}
                    {/*    </div>*/}
                    {/*  </Link>*/}
                    {/*</div>*/}
                    <div className="item group">
                      <Link to="/profile#order">
                        <div className="flex space-x-3 items-center text-qgray hover:text-qblack">
                          <span>
                            <IcoCart />
                          </span>
                          <span className=" font-normal text-base">Order Details</span>
                        </div>
                      </Link>
                    </div>
                    <div className="item group">
                      <Link to="/profile#cancels">
                        <div className="flex space-x-3 items-center text-qgray hover:text-qblack">
                          <span>
                            <IcoCart />
                          </span>
                          <span className=" font-normal text-base">Cancel Orders</span>
                        </div>
                      </Link>
                    </div>
                    <div className="item group">
                      <Link to="/profile#pending">
                        <div className="flex space-x-3 items-center text-qgray hover:text-qblack">
                          <span>
                            <IcoCart />
                          </span>
                          <span className=" font-normal text-base">Pending Orders</span>
                        </div>
                      </Link>
                    </div>
                    <div className="item group">
                      <Link to="/profile#returns">
                        <div className="flex space-x-3 items-center text-qgray hover:text-qblack">
                          <span>
                            <IcoCart />
                          </span>
                          <span className=" font-normal text-base">Return Orders</span>
                        </div>
                      </Link>
                    </div>
                    <div className="item group">
                      <Link to="/profile#review">
                        <div className="flex space-x-3 items-center text-qgray hover:text-qblack">
                          <span>
                            <IcoReviewHand />
                          </span>
                          <span className=" font-normal text-base">My Reviews</span>
                        </div>
                      </Link>
                    </div>
                    {/*<div className="item group">*/}
                    {/*  <Link to="/profile#wishlist">*/}
                    {/*    <div className="flex space-x-3 items-center text-qgray hover:text-qblack">*/}
                    {/*      <span>*/}
                    {/*        <IcoLove />*/}
                    {/*      </span>*/}
                    {/*      <span className=" font-normal text-base">*/}
                    {/*        Wishlist*/}
                    {/*      </span>*/}
                    {/*    </div>*/}
                    {/*  </Link>*/}
                    {/*</div>*/}
                    {/*<div className="item group">*/}
                    {/*  <Link to="/profile#address">*/}
                    {/*    <div className="flex space-x-3 items-center text-qgray hover:text-qblack">*/}
                    {/*      <span>*/}
                    {/*        <IcoAdress />*/}
                    {/*      </span>*/}
                    {/*      <span className=" font-normal text-base">*/}
                    {/*        Address*/}
                    {/*      </span>*/}
                    {/*    </div>*/}
                    {/*  </Link>*/}
                    {/*</div>*/}
                    {/*<div className="item group">*/}
                    {/*  <Link to="/profile#review">*/}
                    {/*    <div className="flex space-x-3 items-center text-qgray hover:text-qblack">*/}
                    {/*      <span>*/}
                    {/*        <IcoReviewHand />*/}
                    {/*      </span>*/}
                    {/*      <span className=" font-normal text-base">*/}
                    {/*        Reviews*/}
                    {/*      </span>*/}
                    {/*    </div>*/}
                    {/*  </Link>*/}
                    {/*</div>*/}
                    <div className="item group">
                      <Link to="/profile#quote">
                        <div className="flex space-x-3 items-center text-qgray hover:text-qblack">
                          <span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            >
                              <circle cx="12" cy="12" r="10"></circle>
                              <path d="M8 14l4 4 4-4M12 10V14"></path>
                            </svg>
                          </span>
                          <span className=" font-normal text-base">
                            Request For Quote
                          </span>
                        </div>
                      </Link>
                    </div>

                    <div className="item group">
                      <Link to="/profile#password">
                        <div className="flex space-x-3 items-center text-qgray hover:text-qblack">
                          <span>
                            <IcoPassword />
                          </span>
                          <span className=" font-normal text-base">
                            Change Password
                          </span>
                        </div>
                      </Link>
                    </div>
                    {/*<div className="item group">*/}
                    {/*  <Link to="/profile#support">*/}
                    {/*    <div className="flex space-x-3 items-center text-qgray hover:text-qblack">*/}
                    {/*      <span>*/}
                    {/*        <IcoSupport />*/}
                    {/*      </span>*/}
                    {/*      <span className=" font-normal text-base">*/}
                    {/*        Support Ticket*/}
                    {/*      </span>*/}
                    {/*    </div>*/}
                    {/*  </Link>*/}
                    {/*</div>*/}
                    <div className="item group " >
                      <Link to="#" onClick={handleLogout}>
                        <div className="flex space-x-3 items-center text-qgray hover:text-qblack">
                          <span>
                            <IcoLogout />
                          </span>
                          <span className=" font-normal text-base">
                            Logout
                          </span>
                        </div>
                      </Link>
                    </div>

                    {/*<div className="item group " >*/}
                    {/*  <Link to="/profile#FeedBackForm" >*/}
                    {/*    <div className="flex space-x-3 items-center text-qgray hover:text-qblack">*/}
                    {/*      <span>*/}
                    {/*        <IcoLogout />*/}
                    {/*      </span>*/}
                    {/*      <span className=" font-normal text-base">*/}
                    {/*        New tab*/}
                    {/*      </span>*/}
                    {/*    </div>*/}
                    {/*  </Link>*/}
                    {/*</div>*/}
                  </div>
                </div>
                <div className="flex-1 rightBar">
                  <div className="item-body dashboard-wrapper w-full">
                    {active === "dashboard" ? (
                      <Dashboard userData={userData} />
                    ) : active === "profile" ? (
                      <>
                        <ProfileTab userData={userData} />
                      </>
                    ) : active === "payment" ? (
                      <>
                        <Payment />
                      </>
                    ) : active === "order" ? (
                      <>
                        <OrderTab userData={userData} />
                      </>
                    )  : active === "pending" ? (
                      <>
                        <PendingTab userData={userData} />
                      </>
                    ) : active === "cancels" ? (
                      <>
                        <CancelTab userData={userData} />
                      </>
                    ) : active === "returns" ? (
                      <>
                        <ReturnTab userData={userData} />
                      </>
                    ) : active === "wishlist" ? (
                      <>
                        <WishlistTab />
                      </>
                    ) : active === "address" ? (
                      <>
                        <AddressesTab />
                      </>
                    ) : active === "quote" ? (
                      <>
                        <Quote userData={userData} />
                      </>
                    ) : active === "password" ? (
                      <>
                        <PasswordTab />
                      </>
                    ) : active === "support" ? (
                      <>
                        <SupportTab />
                      </>
                    ) : active === "review" ? (
                      <>
                        <ReviewTab products={datas.products} />
                      </>
                    )  : active === "FeedBackForm" ? (
                        <>
                          <FeedBackForm />
                        </>
                    ): (
                      ""
                    )}


                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

import { useEffect ,useState} from "react";
import { useLocation } from "react-router-dom";
import { Helmet,HelmetProvider } from 'react-helmet-async';
import Routers from "./Routers";
import axiosConfig from './axiosConfig'; // Your custom Axios instance for API calls
import axios from 'axios'; // Standard Axios library for any other HTTP requests not using your custom config
import React from 'react';

function App() {
  const location = useLocation();
  const { pathname, search } = location;


  useEffect(() => {
    if (location.pathname === "/home-two") {
      document.body.classList.add("home-two");
    } else if (location.pathname === "/home-four") {
      document.body.classList.add("home-four");
    } else if (location.pathname === "/home-five") {
      document.body.classList.add("home-five");
    } else if (location.pathname === "/") {
      document.body.classList.remove("home-two");
      document.body.classList.add("home-one");
    } else {
      document.body.classList.add("home-one");
    }

    return () => {
      document.body.classList.remove("home-two");
      document.body.classList.remove("home-four");
      document.body.classList.remove("home-five");
      document.body.classList.add("home-one");
    };
  }, [location.pathname]);

  const [canonicalURL, setCanonicalURL] = useState('');
  useEffect(() => {
    const { pathname, search } = location;

    let currentURL = window.location.origin + pathname + search;

    let canonicalURL;

    if (window.location.hostname.startsWith("www.")) {
      canonicalURL = currentURL;
    } else {
      canonicalURL = "https://www." + window.location.hostname + pathname + search;
    }

    setCanonicalURL(canonicalURL);
  }, [location.pathname, location.search]);



  const [webSiteData, setWebSiteData] = useState(null);
  const [headerScripts, setHeaderScripts] = useState('');
  const [footerScripts, setFooterScripts] = useState('');

  let authUserData = localStorage.getItem('authUserData');
  useEffect(() => {
    authUserData = authUserData ? JSON.parse(authUserData) : null;

    //authUserData.user have multiple website data want to set data by url match
    if(authUserData && authUserData.user && authUserData.user.websites){
      let currentWebsite = authUserData.user.websites.find((website) => {
        return website.url == process.env.REACT_APP_DOMAIN_NAME
      })
      if(currentWebsite){
        setWebSiteData(currentWebsite)
        if (currentWebsite && currentWebsite?.config?.header_script) {
          setHeaderScripts(currentWebsite.config.header_script);
          setFooterScripts(currentWebsite.config.footer_script);
        }
      }
    }
  },[authUserData])


  // const htmlStrToReactComponent = str => {
  //   const dom = new DOMParser().parseFromString('<script src="https://cdnnew.convobird.com/media/index.js" token="f0497d19-2bf0-47b6-93d3-4659a168020c" /><script type="text/javascript">alert(\'hello\')</script>', 'text/html')
  //   const el = dom.documentElement.querySelector(':not(html):not(head):not(body)')
  //   const NodeName = el.nodeName.toLowerCase()
  //   const attributes = Object.fromEntries([...el.attributes]
  //       .map(({ name, value }) => [name, value]))
  //
  //   return <NodeName {...attributes} />
  // }
  useEffect(() => {
    if (headerScripts) {
      // Create a temporary div to parse and execute the script tags
      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = headerScripts;

      // Extract script tags and append them to the document head
      Array.from(tempDiv.getElementsByTagName('script')).forEach(script => {
        const newScript = document.createElement('script');
        newScript.text = script.innerHTML;
        if (script.src) {
          newScript.src = script.src;
          newScript.async = true;
        }
        document.head.appendChild(newScript);
      });
    }
  }, [headerScripts]);

  useEffect(() => {
    if (footerScripts) {
      // Create a temporary div to parse and execute the script tags
      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = footerScripts;

      // Extract script tags and append them to the document head
      Array.from(tempDiv.getElementsByTagName('script')).forEach(script => {
        const newScript = document.createElement('script');
        newScript.text = script.innerHTML;
        if (script.src) {
          newScript.src = script.src;
          newScript.async = true;
        }
        document.head.appendChild(newScript);
      });
    }
  }, [footerScripts]);

  return (
      <>
        <Helmet defaultTitle='Maximize Performance with essential Computer Components | ETechBuy.com'>
          <meta name="title" content="Maximize Performance with essential Computer Components | ETechBuy.com" />
          <meta name="description" content="Discover premium-quality computer components to enhance speed, power, and efficiency, ensuring an upgraded computing experience like never before at ETechBuy.com" />
          <meta property="og:title" content="Maximize Performance with essential Computer Components | ETechBuy.com" />
          <meta property="og:description" content="Discover premium-quality computer components to enhance speed, power, and efficiency, ensuring an upgraded computing experience like never before at ETechBuy.com" />
          <meta property="og:image" content="https://etechbuy.com/favico.svg" />
          <meta property="og:type" content="website" />
          <meta property="og:url" content={window.location.href} />
          <meta property="og:site_name" content="EtechBuy.com" />
          <link rel="canonical" href={canonicalURL} />


          {/*{headerScripts && htmlStrToReactComponent(headerScripts)}*/}
          {/*{headerScripts && htmlStrToReactComponent(footerScripts)}*/}
          {/*<script type="text/javascript">*/}
          {/*  {footerScripts && footerScripts}*/}
          {/*</script>*/}


          <script type="text/javascript">
            {`
            (function(w,d,t,r,u){
              var f,n,i;
              w[u]=w[u]||[],
              f=function(){
                var o={ti:"211046995", enableAutoSpaTracking: true};
                o.q=w[u],
                w[u]=new UET(o),
                w[u].push("pageLoad")
              },
              n=d.createElement(t),
              n.src=r,
              n.async=1,
              n.onload=n.onreadystatechange=function(){
                var s=this.readyState;
                s&&s!=="loaded"&&s!=="complete"||(f(),n.onload=n.onreadystatechange=null)
              },
              i=d.getElementsByTagName(t)[0],
              i.parentNode.insertBefore(n,i)
            })(window,document,"script","//bat.bing.com/bat.js","uetq");
          `}
          </script>

        </Helmet>

        <Routers />
      </>
  );
}

export default App;

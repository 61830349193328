import {useEffect, useState,useRef} from "react";
import "react-input-range/lib/css/index.css";
import productDatas from "../../data/products.json";
import ProductCardStyleOne from "../Helpers/Cards/ProductCardStyleOne";
import DataIteration from "../Helpers/DataIteration";
import Layout from "../Partials/Layout";
import ProductsFilter from "./ProductsFilter";
import {
  getHomeMainCategoryDetail, getHomeMainManufacturerDetail, getHomeNavbarDetail,
  getHomeWebsitemanufacturersDetail
} from "../../actions/homeAction";
import {connect} from "react-redux";
import {useLocation} from "react-router-dom";
import BannerAds from "./BannerAds";
import {getProductFilters, getProducts} from "../../actions/productAction";
import ProductCardStyleOriginal from "../Helpers/Cards/ProductCardStyleOriginal";
import BreadcrumbComOriginal from "../Wishlist/BreadcrumbComOriginal";
import ProductsFilterOriginal from "./ProductsFilterOriginal";
import ReactHtmlParser from "react-html-parser";
import SectionStyleFour from "../Helpers/SectionStyleFour";
import ViewMoreTitle from "../Helpers/ViewMoreTitle";
import BestSellers from "../Home/BestSellers";
import ChildCategoryView from "../Helpers/ChildCategoryView";
import ChildCategories from "../Home/ChildCategories";
import PaginationComponent from "../Pagination/PaginationComponent";
import { useNavigate } from 'react-router-dom';
import LoaderStyleOne from "../Helpers/Loaders/LoaderStyleOne";
import Loader from "../loader";
import { Helmet } from "react-helmet-async";
import ChildBrandCategories from "../Home/ChildBrandCategories";
import {getReviewByCategoryId} from "../../actions/customerAction";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import ClientReviewCard from "../ClientReviewCard/ClientReviewCard";

const AllProductPage = (props) => {
  const { className,type} = props;
  const [volume, setVolume] = useState({ min: 0, max: 5000 });
  const [loading, setLoading] = useState(true);
  const [filterClear, setFilterClear] = useState(false);
  const location = useLocation();
  const pathname = location.pathname;
  const segments = pathname.split('/').filter((segment) => segment !== '');
  const navigate = useNavigate();
  const headerMenuData = location.state && location.state.headerMenuData;

  const [brandData, setBrandData] = useState(null);
  const [mainCategoryId, setMainCategoryId] = useState(null);
  const [cleanedCategoryUrl, setCleanedCategoryUrl] = useState('');
  const [cleanedCategoriesUrl, setCleanedCategoriesUrl] = useState('');
  const [cleanedBrandUrl, setCleanedBrandUrl] = useState('');
  const [categoryTitle, setCategoryTitle] = useState('');
  const [categoryHeaderDescription, setCategoryHeaderDescription] = useState('');
  const [categoryFooterDescription, setCategoryFooterDescription] = useState('');
  const [parentCategory, setParentCategory] = useState('');
  const [navbarDetailData, setNavbarDetailData] = useState({});
  const [selectedSortOption, setSelectedSortOption] = useState("Default");
  const [showListing, setShowListing] = useState(true);

  const responsive = {
    superLargeDesktop: {
      // For very large screens
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };


  // category detail & manufacturer detail & navbar detail Api calling START
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (parentCategory === "category" && cleanedCategoryUrl !== null && cleanedCategoryUrl !== '') {
          let cleanedUrl = cleanedCategoryUrl;
          if (!cleanedUrl.endsWith('.html')) {
            cleanedUrl += '.html';
          }
          await props.getHomeMainCategoryDetail(cleanedUrl);
        }else if (parentCategory === "categories" && cleanedCategoriesUrl !== null && cleanedCategoriesUrl !== '') {
          let cleanedUrl = cleanedCategoriesUrl;
          if (!cleanedUrl.endsWith('.html')) {
            cleanedUrl += '.html';
          }
          await props.getHomeNavbarDetail(cleanedUrl);
        }else if (parentCategory === "manufacturer" && cleanedBrandUrl !== '') {
          let cleanedUrl = cleanedBrandUrl;
          if (!cleanedUrl.endsWith('.html')) {
            cleanedUrl += '.html';
          }
          const urlParts = cleanedUrl.split('/');
          const lastPart = urlParts[urlParts.length - 1];
          await props.getHomeWebsitemanufacturersDetail(lastPart);
        }
        // Add more logic here if needed
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData(); // Call the async function
  }, [parentCategory,cleanedCategoriesUrl, cleanedCategoryUrl, cleanedBrandUrl,props.getHomeNavbarDetail,props.getHomeMainCategoryDetail, props.getHomeWebsitemanufacturersDetail]);
  // category detail & manufacturer detail & navbar detail Api calling END


  useEffect(() => {
    if(props.main_category_detail_res?.data?.category?.id !== undefined){
      props.getReviewByCategoryId(props.main_category_detail_res?.data?.category?.id);
    }
  },[props.main_category_detail_res]);

  const [reviewData, setReviewData] = useState([]);
  useEffect(() => {
    let reviewPorducts = [];
    if (props.review_by_category_res.data.length > 0) {
      reviewPorducts = props.review_by_category_res.data.map((item, index) => {

        // Find the base image
        let baseImage = null;
        let thumbnailImage = null;
        if (item?.website_order_product?.website_product?.product?.images) {
          const images = item?.website_order_product?.website_product?.product?.images;
          for (const image of images) {
            const customProperties = image.custom_properties;
            if (customProperties && customProperties.image_type && customProperties.image_type.includes("Base")) {
              baseImage = image.original_url;
              if (image.thumbnail !== null && image.thumbnail !== undefined && image.thumbnail !== '') {
                thumbnailImage = image.thumbnail;
              } else {
                thumbnailImage = image.original_url
              }
              break;
            }
          }
        }


        if (baseImage === null) {
          if (item?.website_order_product?.website_product?.website_category?.image) {
            if (item?.website_order_product?.website_product?.website_category?.image.length > 0) {
              let categoryMedia = item?.website_order_product?.website_product?.website_category?.image[0];
              baseImage = categoryMedia?.original_url
              if (categoryMedia.thumbnail !== null && categoryMedia.thumbnail !== undefined && categoryMedia.thumbnail !== '') {
                thumbnailImage = categoryMedia.thumbnail;
              } else {
                thumbnailImage = categoryMedia?.original_url
              }
            }
          }
        }
        //
        let sell_price = '';

        const currentDate = new Date();
        const specialPriceTo = new Date(item?.website_order_product?.website_product.special_price_to);
        const specialPriceFrom = new Date(item?.website_order_product?.website_product.special_price_from);

        if (item?.website_order_product?.website_product.special_price !== null && item?.website_order_product?.website_product.special_price !== 0 && currentDate >= specialPriceFrom && currentDate <= specialPriceTo) {
          sell_price = item?.website_order_product?.website_product.special_price;
        } else {
          sell_price = item?.website_order_product?.website_product.sell_price;
        }

        return {
          id: item.id,
          product_id: item?.website_order_product?.website_product?.id,
          redirect_url: item?.website_order_product?.website_product.redirect_url,
          short_description: "",
          long_description: "",
          cost_price: item?.website_order_product?.website_product.cost_price,
          sell_price: item?.website_order_product?.website_product.sell_price,
          special_price: item?.website_order_product?.website_product.special_price,
          discount: item?.website_order_product?.website_product.discount,
          qty: item?.website_order_product?.website_product.qty,
          meta_title: item?.website_order_product?.website_product.meta_title,
          meta_description: "",
          meta_keywords: "",
          product: item?.website_order_product?.website_product.product,
          special_price_to: item?.website_order_product?.website_product.special_price_to,
          special_price_from: item?.website_order_product?.website_product.special_price_from,
          attributes: "",
          url: item?.website_order_product?.website_product.url,
          title: item?.website_order_product?.website_product.title,
          thumbnailImage: thumbnailImage,
          image: baseImage, // Use the base image if available, otherwise fallback to a default image
          brand: "",
          review: item?.product_rating,
          // offer_price: sell_price,
          price: sell_price,
          campaingn_product: false,
          cam_product_available: null,
          cam_product_sale: null,
          product_type: null,
          customer_name  : item?.website_order?.website_customer_firstname,
          review_date : item?.created_at,
          remarks : item?.remarks,
          product_rating : item?.product_rating,
        };
      });
    }else{
      reviewPorducts = []
    }
    setReviewData(reviewPorducts);
  } , [props.review_by_category_res]);


  //Detail Api fetching Start
  useEffect(() => {

    if (props.main_category_detail_res && props.main_category_detail_res?.data ) {
      setMainCategoryId(props.main_category_detail_res?.data?.category?.id)
      setCategoryTitle(ReactHtmlParser(props.main_category_detail_res?.data.title));
      setCategoryHeaderDescription(ReactHtmlParser(props.main_category_detail_res?.data.header_description));
      setCategoryFooterDescription(ReactHtmlParser(props.main_category_detail_res?.data.footer_description));
    }

    if(props.mainManufacturer_detail_res && props.mainManufacturer_detail_res?.data ){
      if(Object.keys(props.mainManufacturer_detail_res.data).length > 0){
        setBrandData(props.mainManufacturer_detail_res.data);
      }
    }

    if(props.navbar_detail_res && props.navbar_detail_res?.data ){
      setNavbarDetailData(props.navbar_detail_res?.data);
    }

    let parentCy = '';
    segments.forEach((path, index) => {
      if (index === 0) {
        setParentCategory(path);
        parentCy = path;
        return; // exit the loop after setting parentCategory
      }
    });

    // find base path
    if(parentCy == 'category'){
      setCleanedCategoryUrl(pathname.replace(/^\/category\//, ''));
    } else if(parentCy == 'categories'){
      setCleanedCategoriesUrl(pathname.replace(/^\/categories\//, ''));
    }else if(parentCy == "manufacturer"){
      setCleanedBrandUrl(pathname.replace(/^\/manufacturer\//, ''));
    }else{
      setCleanedBrandUrl(pathname.replace(/^\/search\//, ''));
    }

  }, [props.main_category_detail_res,props.mainManufacturer_detail_res ,props.navbar_detail_res,location.pathname,location.search]);
  //Detail Api fetching End


  // product Filter Api calling Start
  useEffect(() => {
    const fetchDataFilters = async () => {
      if (brandData !== null && brandData !== undefined && parentCategory === 'manufacturer') {
        if (Object.keys(brandData).length > 0) {
          await props.getProductFilters("manufacturer_id=" + brandData.id);
        }
      } else if (mainCategoryId !== null && mainCategoryId !== undefined && parentCategory === 'category') {
        await props.getProductFilters("category_id=" + mainCategoryId);
      } else if (parentCategory === 'categories') {
        await props.getProductFilters();
      }
      else  if(parentCategory === 'search'){
        await props.getProductFilters();
      }
    };
    fetchDataFilters();
  }, [brandData, mainCategoryId, props.getProductFilters,parentCategory]);
  // product Filter Api END

  //////////product start
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalProducts, setTotalProducts] = useState(1);
  const [transformedProducts, setTransformedProducts] = useState([]);

  useEffect(() => {
    // Update total pages when the product response changes
    if (props.product_res && props.product_res.links) {
      setTotalPages(props.product_res.meta.last_page);
    }
  }, [props.product_res]);


  // page handle when page change
  const handlePageChange = (newPage) => {
    setLoading(true);
    setCurrentPage(newPage);
  };

  // product listing Api calling
  const fetchData = async () => {
    if (brandData !== null && brandData !== undefined && parentCategory === 'manufacturer') {
      const filterData = await formatFilter('manufacture', null, brandData.id);
      await props.getProducts(filterData, '', currentPage);
      // await props.getProductFilters("manufacturer_id=" + brandData.id);
      setLoading(false);
    } else if (mainCategoryId !== null && mainCategoryId !== undefined && parentCategory === 'category') {
      const filterData = await formatFilter('category', mainCategoryId);
      await props.getProducts(filterData, currentPage);
      // await props.getProductFilters("category_id=" + mainCategoryId);
      setLoading(false);
    } else if (navbarDetailData !== undefined && Object.keys(navbarDetailData).length > 0 && parentCategory === 'categories') {
      const filterData = await formatFilter('categories',null,null,navbarDetailData);
      await props.getProducts(filterData, currentPage);
      setLoading(false);
    }else if(parentCategory === 'search'){
      const filterData = await formatFilter('search', null, null);
      await props.getProducts(filterData, currentPage);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [brandData, mainCategoryId,navbarDetailData,parentCategory,currentPage]);

  // format filter Params
  const formatFilter = async (type, categoryId = null, manufactureId = null,navbarDetailData=null) => {
    if (type === "category") {
      let data = {
        type: "category",
        categoryId: categoryId,
        params :location.search
      };
      return data;
    } else if (type === "categories") {
      let data = {
        type: "categories",
        navbarDetail: navbarDetailData,
        params :location.search
      };
      return data;

    } else if (type === "manufacture") {
      let data = {
        type: "manufacture",
        manufactureId: manufactureId,
        params :location.search
      };
      return data;
    }else {
      const searchPath = location.pathname.split('/search/')[1];
      let data = {
        type: "search",
        title : searchPath,
        params :location.search
      };
      return data;
    }
  }
  // format filter Params END


// Use a separate useEffect to log the transformedProducts
  const transformedData= async () => {
    if (props.product_res && props.product_res.data.length > 0) {
      const transformedArray = props.product_res.data.map((data) => {
        // Find the base image
        let baseImage = null;
        let thumbnailImage = null;
        if (data?.product?.media) {
          const images = data.product.media;
          for (const image of images) {
            const customProperties = image.custom_properties;
            if (customProperties && customProperties.image_type && customProperties.image_type.includes("Base")) {
              baseImage = image.original_url;
              if(image.thumbnail !== null && image.thumbnail !== undefined && image.thumbnail !== ''){
                thumbnailImage = image.thumbnail;
              }else{
                thumbnailImage = image.original_url;
              }
              break;
            }
          }
        }
        if(baseImage === null){
          if(data?.website_category?.media){
            if(data?.website_category?.media.length > 0){
              let categoryMedia = data?.website_category?.media[0];
              baseImage = categoryMedia?.original_url

              if(categoryMedia.thumbnail !== null && categoryMedia.thumbnail !== undefined && categoryMedia.thumbnail !== ''){
                thumbnailImage = categoryMedia.thumbnail;
              }
              else{
                thumbnailImage = categoryMedia?.original_url;

              }
            }
          }
        }

        let sell_price = '';

        const currentDate = new Date();
        const specialPriceTo = new Date(data.special_price_to);
        const specialPriceFrom = new Date(data.special_price_from);

        if (data.special_price !== null && data.special_price != 0 && currentDate >= specialPriceFrom && currentDate <= specialPriceTo) {
          sell_price = data.special_price;
        }else {
          sell_price = data.sell_price;
        }

        return {
          id: data.id,
          product_id: data?.product.id,
          redirect_url: data?.redirect_url,
          short_description: data.short_description,
          long_description: data.long_description,
          cost_price: data.cost_price,
          sell_price: data.sell_price,
          special_price: data.special_price,
          special_price_from: data.special_price_from,
          special_price_to: data.special_price_to,
          discount: data.discount,
          qty: data.qty,
          meta_title: data.meta_title,
          meta_description: data.meta_description,
          meta_keywords: data.meta_keywords,
          product: data.product,
          attributes: data.attributes,
          url: data.url,
          title: data.title,
          image: baseImage, // Use the base image if available, otherwise fallback to a default image
          thumbnailImage: thumbnailImage, // Use the base image if available, otherwise fallback to a default image
          brand: "",
          review: 0,
          // offer_price: sell_price,
          price: sell_price,
          campaingn_product: false,
          cam_product_available: null,
          cam_product_sale: null,
          product_type: null
        };
      });
      setTransformedProducts(transformedArray);

    }else {
      setTransformedProducts([]); // Fix here
    }
  }
  useEffect(() => {
    setTotalProducts(props.product_res.meta.total);
    transformedData();
  }, [props.product_res]);

  //////////product end


  //filters Handling Start
// filter checkbox and text box start
  const [checkboxNames, setCheckboxNames] = useState([]);
  const [checkboxAttributeId, setCheckboxAttributeId] = useState('');
  const [inputValue, setInputValue] = useState([]);
  const [commaSeparatedValues, setCommaSeparatedValues] = useState('');
  const [filterChange, setFilterChange] = useState('');

  useEffect(() => {
    // This effect will run whenever the search filter changes
    const currentSearchFilter = location.pathname.split('/').pop(); // e.g., 'ddr2' from '/search/ddr2'

    setTimeout(() => {
      setFilterChange(currentSearchFilter);
    }, 100);

  }, [location.pathname]); // Only re-run the effect when the pathname changes


  const checkboxHandler = (e) => {
    const { name, checked } = e.target;
    const attributeId = e.target.getAttribute('attributeId');

    setCheckboxAttributeId(attributeId);
    setCheckboxNames((prevCheckboxes) => {
      const existingCheckbox = prevCheckboxes.find((checkbox) => checkbox[attributeId] === name);

      if (checked && !existingCheckbox) {
        return [...prevCheckboxes, { [attributeId]: name }];
      } else if (!checked && existingCheckbox) {
        const updatedCheckboxes = prevCheckboxes.filter((checkbox) => checkbox[attributeId] !== name);

        if (updatedCheckboxes.length === 0) {
          const targetInputValue = inputValue.find((item) => item.attribute_id === attributeId);
          handleCheckboxFilter(attributeId, '', targetInputValue.value);
        }
        return updatedCheckboxes;
      }

      return prevCheckboxes;
    });
  };

  const inputHandler = (event) => {
    const checkboxExists = event.target.getAttribute('checkboxExists');
    const attributeId = event.target.getAttribute('attributeId');
    const attributeValue = event.target.value;

    // Set inputValue against attribute_id
    setInputValue((prevInputValue) => [
      ...prevInputValue.filter((item) => item.attribute_id !== attributeId), // Remove existing entry for the same attribute_id
      { attribute_id: attributeId, value: event.target.value }, // Add new entry
    ]);

    if (checkboxExists !== 'true') {
      handleAttributeFilter(attributeId, attributeValue);
    } else {
      if (commaSeparatedValues !== '') {
        if (checkboxAttributeId !== attributeId) {
          handleCheckboxFilter(attributeId, '', event.target.value);
        } else {
          handleCheckboxFilter(attributeId, commaSeparatedValues, event.target.value);
        }
      }
    }
  };

  useEffect(() => {
    if (checkboxNames.length > 0 ) {
      const values = checkboxNames.map((data) => data[checkboxAttributeId]);
      const commaSeparatedValues = values.join(',');
      setCommaSeparatedValues(commaSeparatedValues);
      const targetInputValue = inputValue.find((item) => item.attribute_id === checkboxAttributeId);
      if(targetInputValue !== undefined){
        handleCheckboxFilter(checkboxAttributeId, commaSeparatedValues, targetInputValue.value);
      }
    }
  }, [checkboxNames]); // Added inputValue to the dependency array

  const handleCheckboxFilter = (attributeId, checkboxNames, attributeValue) => {
    setLoading(true);
    setCurrentPage(1);
    const params = new URLSearchParams(window.location.search);
    let searchValues = '';

    if (checkboxNames !== '') {
      const checkboxArray = checkboxNames.split(',');
      searchValues = checkboxArray
          .filter((name) => attributeValue && name)
          .map((name) => `${attributeValue}:${name}`);
      params.set(`filter[attribute:${attributeId}]`, searchValues);
    } else {
      params.delete(`filter[attribute:${attributeId}]`);
    }
    const updatedUrl = `${window.location.pathname}?${params.toString()}`;

    // Navigate to the updated URL
    navigate(updatedUrl);
    // After a delay of 0.3 seconds, trigger setFilterChange
    setTimeout(() => {
      setFilterChange(updatedUrl);
    }, 300);
  };


  const handleAttributeFilter = (attributeId, attributeValue) => {
    const params = new URLSearchParams(window.location.search);
    params.set(`filter[attribute:${attributeId}]`, attributeValue);

    const updatedUrl = `${window.location.pathname}?${params.toString()}`;
    setFilterChange(updatedUrl);
    navigate(updatedUrl);
  };

// filter checkbox and text box END

  // when filter change hit Api
  // useEffect(() => {
  //   if(location.search !== ''){
  //     fetchData();
  //     transformedData();
  //   }
  // }, [location.search]);

  // filter dropdown START

  const handleAttributeDropdownSelect = (attributeName, attributeId, selectedValue) => {
    setLoading(true);
    const params = new URLSearchParams(window.location.search);
    setCurrentPage(1);
    if(attributeName === 'category'){
      params.set(`filter[website_category_id]`, selectedValue);
    }else if(attributeName === 'manufacture'){
      params.set(`filter[website_manufacturer_id]`, selectedValue);
    }else{
      params.set(`filter[attribute:${attributeId}]`, selectedValue);
    }
    const updatedUrl = `${window.location.pathname}?${params.toString()}`;

    navigate(updatedUrl);
    // After a delay of 0.3 seconds, trigger setFilterChange
    setTimeout(() => {
      setFilterChange(updatedUrl);
    }, 300);
  };

  // filter dropdown END


  //price handle start
  const yourFunctionToCallOnVolumeChange = (newVolume) => {
    const params = new URLSearchParams(window.location.search);
    params.set(`filter[sell_price]`, newVolume.min+'|'+newVolume.max);
    const updatedUrl = `${window.location.pathname}?${params.toString()}`;
    navigate(updatedUrl);
    // After a delay of 0.3 seconds, trigger setFilterChange
    setTimeout(() => {
      setFilterChange(updatedUrl);
    }, 300);
  };


  const isFirstRender = useRef(true);

  useEffect(() => {
    if (!isFirstRender.current) {
      yourFunctionToCallOnVolumeChange(volume);
    } else {
      isFirstRender.current = false;
    }
  }, [volume]);


  ///Sort handling Data
  const sortOptions = [
    { label: "Sort by oldest", value: "id" },
    { label: "Sort by latest", value: "-id" },
    { label: "Price Low - High", value: "sell_price" },
    { label: "Price High - Low", value: "-sell_price" },
    { label: "Name A-Z", value: "title" },
    { label: "Name Z-A", value: "-title" },
  ];

  const handleSortChange = (e) => {
    setLoading(true);
    const selectedValue = e.target.value;
    setSelectedSortOption(selectedValue);
    const params = new URLSearchParams(window.location.search);
    params.set(`sort`, selectedValue);
    const updatedUrl = `${window.location.pathname}?${params.toString()}`;
    navigate(updatedUrl);
    // After a delay of 0.3 seconds, trigger setFilterChange
    setTimeout(() => {
      setFilterChange(updatedUrl);
    }, 300);
  };


  const volumeHandler = (value) => {
    setVolume(value);
  };
  const [filterToggle, setToggle] = useState(false);
  //price handleb end


  //when Side filters change product Api function Calling

  useEffect(()=>{
    fetchData();
  }, [filterChange]);

  // For Scroll Top
  useEffect(() => {
    setLoading(true);
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };
    scrollToTop();
  }, [location.pathname]);


  useEffect(() => {
    let childrenCategories = props.main_category_detail_res?.data?.children;
    if(childrenCategories !== undefined && childrenCategories.length === 0){
      setShowListing(true)
    }
    if(childrenCategories !== undefined && childrenCategories.length > 0){
      setShowListing(false)
    }

  }, [props.main_category_detail_res,brandData?.website_categories]);

  //filters END
  return (
      <>
        {parentCategory === "category" &&
          <Helmet prioritizeSeoTags>
            <title>{props?.main_category_detail_res?.data?.meta_title}</title>
            <meta name="keywords" content={props?.main_category_detail_res?.data?.meta_keywords} />
            <meta name="description" content={props?.main_category_detail_res?.data?.meta_description} />
            <meta name="title" content={props?.main_category_detail_res?.data?.meta_title} />
            <meta name="description" content={props?.main_category_detail_res?.data?.meta_description} />
            <meta property="og:title" content={props?.main_category_detail_res?.data?.meta_title} />
            <meta property="og:description" content={props?.main_category_detail_res?.data?.meta_description} />
            <meta property="og:image" content={props?.main_category_detail_res?.data?.image && props?.main_category_detail_res?.data?.image.length >0 ? props?.main_category_detail_res?.data?.image[0].original_url:''} />
            <meta property="og:type" content="website" />
            <meta property="og:url" content={window.location.href} />
            <meta property="og:site_name" content="EtechBuy.com" />
          </Helmet>
        }
        {parentCategory === "manufacturer" &&
          <Helmet prioritizeSeoTags>
            <title>{brandData?.meta_title}</title>
            <meta name="title" content={brandData?.meta_title} />
            <meta name="keywords" content={brandData?.meta_keywords} />
            <meta name="description" content={brandData?.meta_description} />

            <meta property="og:title" content={brandData?.meta_title} />
            <meta property="og:description" content={brandData?.meta_description} />
            <meta property="og:image" content={brandData?.website_banner?.image && brandData?.website_banner?.image.length > 0 ? [brandData.website_banner.image[0].original_url] : ''} />
            <meta property="og:type" content="website" />
            <meta property="og:url" content={window.location.href} />
            <meta property="og:site_name" content="EtechBuy.com" />
          </Helmet>
        }
        {
          (parentCategory === "categories" && navbarDetailData !== undefined && Object.keys(navbarDetailData).length > 0) &&
          <Helmet prioritizeSeoTags>

            <title>{navbarDetailData?.meta_title}</title>
            <meta name="title" content={navbarDetailData?.meta_title} />
            <meta name="keywords" content={navbarDetailData?.meta_keywords} />
            <meta name="description" content={navbarDetailData?.meta_description} />

            <meta property="og:title" content={navbarDetailData?.meta_title} />
            <meta property="og:type" content="website" />
            <meta property="og:url" content={window.location.href} />
            <meta property="og:site_name" content="EtechBuy.com" />
          </Helmet>
        }
        <Layout>
          <div className="products-page-wrapper w-full">
            <div className="container-x mx-auto">
              <BreadcrumbComOriginal />
              {parentCategory === "manufacturer" && (
                  <>
                    <h1 className='font-bold'>{brandData?.website_banner?.title}</h1>
                    <BannerAds
                        ads={brandData?.website_banner?.image && brandData?.website_banner?.image.length > 0 ? [brandData.website_banner.image[0].original_url] : []}
                        title={brandData?.website_banner?.title}
                        className="products-ads-section mb-[0px] mt-[20px]"
                    />
                      <ChildBrandCategories  categoryData={brandData?.website_categories} />
                  </>

              )}
              {parentCategory === "category" && (
                  <>
                    <h1 className='font-bold'>
                      {categoryTitle}
                    </h1>
                    <div className="w-full overflow-hidden mb-[40px] mt-[20px]" >
                      {categoryHeaderDescription}
                    </div>
                  </>
              )}
              {parentCategory === "category" && (
                  <ChildCategoryView
                      className="best-sallers-section mb-[60px]"
                      seeMoreUrl="/sallers"
                      categoryData={props.main_category_detail_res}
                      categoryTitle={categoryTitle}
                  >
                    <ChildCategories  categoryData={props.main_category_detail_res} />
                  </ChildCategoryView>
              )}

              {showListing &&
                  <div className="w-full lg:flex lg:space-x-[30px]">
                <div className="lg:w-[270px]">

                  <ProductsFilterOriginal
                      filterToggle={filterToggle}
                      setFilterClear={setFilterClear}
                      checkboxNames={checkboxNames}
                      setFilterChange={setFilterChange}
                      filterToggleHandler={() => setToggle(!filterToggle)}
                      productFilterRes={props.product_filter_res}
                      // filters={filters}
                      handleAttributeDropdownSelect={handleAttributeDropdownSelect}
                      checkboxHandler={checkboxHandler}
                      inputHandler={inputHandler}
                      inputValue={inputValue}
                      volume={volume}
                      volumeHandler={(value) => setVolume(value)}
                      // storage={storage}
                      // filterstorage={filterStorage}
                      className="mb-[30px]"
                  />
                  {/* ads */}
                  {/*<div className="w-full hidden lg:block h-[295px]">*/}
                  {/*  <img*/}
                  {/*    src={`${process.env.PUBLIC_URL}/assets/images/ads-5.png`}*/}
                  {/*    alt=""*/}
                  {/*    className="w-full h-full object-contain"*/}
                  {/*  />*/}
                  {/*</div>*/}
                </div>

                <div className="flex-1">
                  <div className="products-sorting w-full bg-white md:h-[70px] flex md:flex-row flex-col md:space-y-0 space-y-5 md:justify-between md:items-center p-[30px] mb-[40px]">
                    <div>
                      <p className="font-400 text-[13px]">
                        <span className="text-qgray"> Showing</span> 1–{12} of {totalProducts} Results
                      </p>
                    </div>
                    <div>
                      <p className="font-400 text-[13px]">
                        <PaginationComponent
                            currentPage={currentPage}
                            totalPages={totalPages}
                            onPageChange={handlePageChange}
                        />
                      </p>
                    </div>
                    <div className="flex space-x-3 items-center">
                      <span className="font-400 text-[13px]">Sort by:</span>
                      <div className="flex space-x-3 items-center border-b border-b-qgray">
                        <select
                            className="test"
                            onChange={handleSortChange}
                            style={{
                              backgroundColor: "#08456B",
                              color: "white",
                              margin: "5px",
                              width: "235px",
                              padding: 0,
                            }}
                        >
                          <option value="" >Select an option</option>
                          {sortOptions.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <button
                        onClick={() => setToggle(!filterToggle)}
                        type="button"
                        className="w-10 lg:hidden h-10 rounded flex justify-center items-center border border-qyellow text-qyellow"
                    >
                      <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          strokeWidth="2"
                      >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z"
                        />
                      </svg>
                    </button>
                  </div>

                  {/*<ListComponent itemPerPage={perPage} current_page={currentPage} totalItem={pageTotal}/>*/}

                  {loading ? (
                      <Loader></Loader>
                  ) : (
                      <div className="grid xl:grid-cols-3 sm:grid-cols-2 grid-cols-1 xl:gap-[30px] gap-2 mb-[40px]">
                        <DataIteration datas={transformedProducts} startLength={0} endLength={transformedProducts.length}>
                          {({ datas }) => (
                              <div data-aos="fade-up" key={datas.id}>
                                <ProductCardStyleOriginal datas={datas} />
                              </div>
                          )}
                        </DataIteration>
                      </div>
                  )}


                  {/*<div className="w-full h-[164px] overflow-hidden mb-[40px]">*/}
                  {/*  <img*/}
                  {/*    src={`${process.env.PUBLIC_URL}/assets/images/ads-6.png`}*/}
                  {/*    alt=""*/}
                  {/*    className="w-full h-full object-contain"*/}
                  {/*  />*/}
                  {/*</div>*/}
                  {/*<div className="grid xl:grid-cols-3 sm:grid-cols-2 grid-cols-1 xl:gap-[30px] gap-5 mb-[40px]">*/}
                  {/*  <DataIteration*/}
                  {/*    datas={products}*/}
                  {/*    startLength={6}*/}
                  {/*    endLength={15}*/}
                  {/*  >*/}
                  {/*    {({ datas }) => (*/}
                  {/*      <div data-aos="fade-up" key={datas.id}>*/}
                  {/*        <ProductCardStyleOne datas={datas} />*/}
                  {/*      </div>*/}
                  {/*    )}*/}
                  {/*  </DataIteration>*/}
                  {/*</div>*/}
                  <div>
                    <p className="font-400 text-[13px]">
                      <PaginationComponent
                          currentPage={currentPage}
                          totalPages={totalPages}
                          onPageChange={handlePageChange}
                      />
                    </p>
                  </div>
                </div>
              </div>
              }

              {parentCategory === "category" && (
                  <div className="w-full h-[auto] overflow-hidden mb-[40px] mt-3" >
                    {categoryFooterDescription}
                  </div>
              )}


              {reviewData.length > 0 && (
              <>
                  <h2 className="sm:text-3xl text-xl font-600 text-qblacktext leading-none mb-[30px]">
                    Reviews
                  </h2>
                  <Carousel
                      responsive={responsive}
                      ssr={true} // server-side rendering (this can improve performance)
                      infinite={true}
                      autoPlay={true}
                      autoPlaySpeed={1000} // Adjust speed here
                      keyBoardControl={true}
                      customTransition="all .5s"
                      transitionDuration={500} // Faster transitions
                      containerClass="carousel-container"
                      removeArrowOnDeviceType={["tablet", "mobile"]}
                      dotListClass="custom-dot-list-style"
                      itemClass="carousel-item-padding-40-px"
                  >
                    {reviewData.length > 0 && reviewData.map((item) => (
                        <div key={item.id}>
                          <ClientReviewCard datas={item} />
                        </div>
                    ))}
                  </Carousel>
              </>

              )}

            </div>
          </div>
        </Layout>
      </>
  );
}
const mapStateToProps = state => ({
  product_res: state.product.productRes,
  product_loading: state.product.productRes.loading,
  product_filter_res: state.product.productFilterRes,
  navbar_detail_res: state.home.navbarDetailRes,
  main_category_detail_res: state.home.mainCategoryDetailRes,
  mainManufacturer_detail_res: state.home.mainManufacturerDetailRes,
  review_by_category_res: state.customer.reviewByCategoryRes,
});

const mapDispatchToProps = dispatch => ({
  getProducts: (filters,page) => dispatch(getProducts(filters,page)),
  getProductFilters: (filter) => dispatch(getProductFilters(filter)),
  getHomeNavbarDetail : (url) => dispatch(getHomeNavbarDetail(url)),
  getHomeMainCategoryDetail : (url) => dispatch(getHomeMainCategoryDetail(url)),
  getHomeWebsitemanufacturersDetail : (url) => dispatch(getHomeMainManufacturerDetail(url)),
  getReviewByCategoryId : (categoryId) => dispatch(getReviewByCategoryId(categoryId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AllProductPage);

import BreadcrumbCom from "../BreadcrumbCom";
import EmptyCardError from "../EmptyCardError";
import InputCom from "../Helpers/InputCom";
import PageTitle from "../Helpers/PageTitle";
import Layout from "../Partials/Layout";
import ProductsTable from "./ProductsTable";
import {connect} from "react-redux";
import {useShoppingCart} from "use-shopping-cart";
import {useEffect, useState} from "react";
import {Link, useLocation} from 'react-router-dom';
import {getProductDetail, getRelatedProduct} from "../../actions/productAction";
import {toast} from "react-toastify";

const CardPage = (props) => {
  const { cartDetails,clearCart, removeItem,cartCount,totalPrice,addItem,setItemQuantity} = useShoppingCart();
  const {  cart = true ,className,reportHandler,productDetail} = props;

  const [selectedCountry, setSelectedCountry] = useState(''); // State to track the selected country

  const handleCountryChange = (event) => {
    setSelectedCountry(event.target.value);
  };

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  const query = useQuery();
  const productUrl = query.get('product-url');

  useEffect(() => {
    const fetchData = async () => {
      // Simulate API call
      await props.getProductDetail(productUrl);
    };

    if(productUrl){
      fetchData();
    }
  }, [productUrl]);

  useEffect(() => {
    const data = props.product_detail.data;
    if (data && Object.keys(data).length > 0 && productUrl !== null) {
      let product = props.product_detail.data;
      let baseImage = null;
      if (product.product !== null) {
        product.product.media.forEach((mediaItem) => {
          const isBaseImage = mediaItem.custom_properties?.image_type?.includes("Base");
          if (isBaseImage) {
            baseImage = mediaItem.original_url;
            return; // Exit the loop once the base image is found
          }
        });
      }
      if (baseImage === null) {
        if (product?.website_category?.media) {
          if (product?.website_category?.media.length > 0) {
            let categoryMedia = product?.website_category?.media[0];
            baseImage = categoryMedia?.original_url;
          }
        }
      }

      let sell_price = '';
      const currentDate = new Date();
      const specialPriceFrom = new Date(product.special_price_from);
      const specialPriceTo = new Date(product.special_price_to);

      if (product.special_price !== null && product.special_price !== 0 && currentDate >= specialPriceFrom && currentDate <= specialPriceTo) {
        sell_price = product.special_price;
      } else {
        sell_price = product.sell_price;
      }

      let productData = {
        id: product.id,
        product_id: product.product_id,
        title: product.title,
        baseImage: baseImage,
        url: product.url,
        redirect_url: product.redirect_url,
        short_description: product.short_description,
        long_description: product.long_description,
        cost_price: product.cost_price,
        sell_price: product.sell_price,
        special_price: product.special_price,
        price: sell_price,
        discount: product.discount,
        qty: product.qty, // product stock quantity
        meta_title: product.meta_title,
        meta_description: product.meta_description,
        meta_keywords: product.meta_keywords,
        product: product.product,
        attributes: product.attributes,
      };

      // Call addItem function with productData as the first argument
      addItem(productData);
      toast.success(`${product.title} added to the cart!`, {
        position: "top-right",
        autoClose: 3000, // Close the notification after 3000 milliseconds (3 seconds)
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });

    }
  } , [props.product_detail]);



  return (
    <Layout childrenClasses={cart ? "pt-0 pb-0" : ""}>
      {cart === false ? (
        <div className="cart-page-wrapper w-full">
          <div className="container-x mx-auto">
            <BreadcrumbCom
              paths={[
                { name: "home", path: "/" },
                { name: "cart", path: "/cart" },
              ]}
            />
            <EmptyCardError />
          </div>
        </div>
      ) : (
        <div className="cart-page-wrapper w-full bg-white pb-[60px]">
          <div className="w-full">
            <PageTitle
              title="Your Cart"
              breadcrumb={[
                { name: "home", path: "/" },
                { name: "cart", path: "/cart" },
              ]}
            />
          </div>
          <div className="w-full mt-[23px]">
            <div className="container-x mx-auto">
              <ProductsTable className="mb-[30px]" />
              <div className="w-full sm:flex justify-end">
                {/* <div className="discount-code sm:w-[270px] w-full mb-5 sm:mb-0 h-[50px] flex">
                  <div className="flex-1 h-full">
                    <InputCom type="text" placeholder="Discount Code" />
                  </div>
                  <button type="button" className="w-[90px] h-[50px] black-btn">
                    <span className="text-sm font-semibold">Apply</span>
                  </button>
                </div> */}
                <div className="flex space-x-2.5 items-center">
                  <Link to="/">
                    <div className="w-[220px] h-[50px] bg-[#0076bd] flex justify-center items-center text-white">
                      <span className="text-sm font-semibold">
                        Continue Shopping
                      </span>
                    </div>
                  </Link>
                  <Link to="/checkout" className="w-[220px] h-[50px] black-btn my_btn flex justify-center items-center">
                    <span className="text-sm font-semibold">
                      Proceed to Checkout
                    </span>
                  </Link>

                  {/*<a href="#">*/}
                  {/*  <div className="w-[140px] h-[50px] bg-[#F6F6F6] flex justify-center items-center">*/}
                  {/*    <span className="text-sm font-semibold">Update Cart</span>*/}
                  {/*  </div>*/}
                  {/*</a>*/}
                </div>
              </div>
              <div className="w-full mt-[30px] flex sm:justify-end">
                <div className="sm:w-[370px] w-full border border-[#EDEDED] px-[30px] py-[26px]">
                  {/*<div className="sub-total mb-6">*/}
                  {/*  <div className=" flex justify-between mb-6">*/}
                  {/*    <p className="text-[15px] font-medium text-qblack">*/}
                  {/*      Subtotal*/}
                  {/*    </p>*/}
                  {/*    <p className="text-[15px] font-medium text-qred">${totalPrice}</p>*/}
                  {/*  </div>*/}
                  {/*  <div className="w-full h-[1px] bg-[#EDEDED]"></div>*/}
                  {/*</div>*/}
                  <div className="shipping mb-6">
                    {/*<span className="text-[15px] font-medium text-qblack mb-[18px] block">*/}
                    {/*  Shipping*/}
                    {/*</span>*/}
                    <ul className="flex flex-col space-y-1">
                      {/*<li>*/}
                      {/*  <div className="flex justify-between items-center">*/}
                      {/*    <div className="flex space-x-2.5 items-center">*/}
                      {/*      <div className="input-radio">*/}
                      {/*        <input*/}
                      {/*          type="radio"*/}
                      {/*          name="price"*/}
                      {/*          className="accent-pink-500"*/}
                      {/*        />*/}
                      {/*      </div>*/}
                      {/*      <span className="text-[13px] text-normal text-qgraytwo">*/}
                      {/*        Free Shipping*/}
                      {/*      </span>*/}
                      {/*    </div>*/}
                      {/*    <span className="text-[13px] text-normal text-qgraytwo">*/}
                      {/*      +$00.00*/}
                      {/*    </span>*/}
                      {/*  </div>*/}
                      {/*</li>*/}
                      {/*<li>*/}
                      {/*  <div className="flex justify-between items-center">*/}
                      {/*    <div className="flex space-x-2.5 items-center">*/}
                      {/*      <div className="input-radio">*/}
                      {/*        <input*/}
                      {/*          type="radio"*/}
                      {/*          name="price"*/}
                      {/*          className="accent-pink-500"*/}
                      {/*        />*/}
                      {/*      </div>*/}
                      {/*      <span className="text-[13px] text-normal text-qgraytwo">*/}
                      {/*        Flat Rate*/}
                      {/*      </span>*/}
                      {/*    </div>*/}
                      {/*    <span className="text-[13px] text-normal text-qgraytwo">*/}
                      {/*      +$00.00*/}
                      {/*    </span>*/}
                      {/*  </div>*/}
                      {/*</li>*/}
                      {/*<li>*/}
                      {/*  <div className="flex justify-between items-center">*/}
                      {/*    <div className="flex space-x-2.5 items-center">*/}
                      {/*      <div className="input-radio">*/}
                      {/*        <input*/}
                      {/*          type="radio"*/}
                      {/*          name="price"*/}
                      {/*          className="accent-pink-500"*/}
                      {/*        />*/}
                      {/*      </div>*/}
                      {/*      <span className="text-[13px] text-normal text-qgraytwo">*/}
                      {/*        Local Delivery*/}
                      {/*      </span>*/}
                      {/*    </div>*/}
                      {/*    <span className="text-[13px] text-normal text-qgraytwo">*/}
                      {/*      +$00.00*/}
                      {/*    </span>*/}
                      {/*  </div>*/}
                      {/*</li>*/}
                    </ul>
                  </div>
                  <div className="shipping-calculation w-full mb-3">
                    {/*<div className="title mb-[17px]">*/}
                    {/*  <h1 className="text-[15px] font-medium">*/}
                    {/*    Calculate Shipping*/}
                    {/*  </h1>*/}
                    {/*</div>*/}
                    {/*<div className="w-full h-[50px] border border-[#EDEDED] px-5 flex justify-between items-center mb-2">*/}
                    {/*  <span className="text-[13px] text-qgraytwo">Select Country</span>*/}
                    {/*  <div className="relative">*/}
                    {/*    <select*/}
                    {/*        value={selectedCountry}*/}
                    {/*        onChange={handleCountryChange}*/}
                    {/*        className="appearance-none outline-none bg-transparent text-[13px] text-qgraytwo"*/}
                    {/*    >*/}
                    {/*      /!*<option value="" disabled>*!/*/}
                    {/*      /!*  Select a country*!/*/}
                    {/*      /!*</option>*!/*/}
                    {/*      <option value="US">United States</option>*/}
                    {/*      <option value="UK">United Kingdom</option>*/}
                    {/*      <option value="Canada">Canada</option>*/}
                    {/*    </select>*/}
                    {/*  </div>*/}
                    {/*  <span>*/}
                    {/*    <svg*/}
                    {/*      width="11"*/}
                    {/*      height="7"*/}
                    {/*      viewBox="0 0 11 7"*/}
                    {/*      fill="none"*/}
                    {/*      xmlns="http://www.w3.org/2000/svg"*/}
                    {/*    >*/}
                    {/*      <path*/}
                    {/*        d="M5.4 6.8L0 1.4L1.4 0L5.4 4L9.4 0L10.8 1.4L5.4 6.8Z"*/}
                    {/*        fill="#222222"*/}
                    {/*      />*/}
                    {/*    </svg>*/}
                    {/*  </span>*/}
                    {/*</div>*/}
                    {/*<div className="w-full h-[50px]">*/}
                    {/*  <InputCom*/}
                    {/*    inputClasses="w-full h-full"*/}
                    {/*    type="text"*/}
                    {/*    placeholder="Postcode / ZIP"*/}
                    {/*  />*/}
                    {/*</div>*/}
                  </div>
                  {/*<button type="button" className="w-full mb-10">*/}
                  {/*  <div className="w-full h-[50px] bg-[#F6F6F6] flex justify-center items-center">*/}
                  {/*    <span className="text-sm font-semibold">Update Cart</span>*/}
                  {/*  </div>*/}
                  {/*</button>*/}
                  <div className="total mb-6">
                    <div className=" flex justify-between">
                      <p className="text-[18px] font-medium text-qblack">
                        Total
                      </p>
                      <p className="text-[18px] font-medium text-qred">${totalPrice.toFixed(2)}</p>
                    </div>
                  </div>
                  <Link to="/checkout" className="w-full h-[50px] black-btn flex justify-center items-center">
                    <span className="text-sm font-semibold">
                      Proceed to Checkout
                    </span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
}
const mapStateToProps = (state) => ({
  product_detail: state.product.productDetailRes,
});

const mapDispatchToProps = (dispatch) => ({
  getProductDetail: (url) => dispatch(getProductDetail(url)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CardPage);

import React from "react";

const PaginationComponent = ({ currentPage, totalPages, onPageChange }) => {
  const handlePageChange = (newPage) => {
    // Scroll to the top of the page
    window.scrollTo(0, 0);
    onPageChange(newPage);
  };

  const renderPageButtons = () => {
    const visiblePages = 5;
    const halfVisiblePages = Math.floor(visiblePages / 2);

    const startPage = Math.max(currentPage - halfVisiblePages, 1);
    const endPage = Math.min(startPage + visiblePages - 1, totalPages);

    return Array.from(
      { length: endPage - startPage + 1 },
      (_, index) => startPage + index
    ).map((page) => (
      <li
        key={page}
        className={`page-item ${currentPage === page ? "active" : ""}`}
      >
        <button className="page-link" onClick={() => handlePageChange(page)}>
          {page}
        </button>
      </li>
    ));
  };

  return (
    <div className="PagiDiv mt-3">
      <nav aria-label="Page navigation example">
        <ul className="pagination">
          <li className="page-item">
            <button
              className="page-link"
              disabled={currentPage === 1}
              onClick={() => handlePageChange(1)}
              aria-label="First"
            >
              <span aria-hidden="true">First</span>
            </button>
          </li>

          <li className="page-item">
            <button
              className="page-link"
              disabled={currentPage === 1}
              onClick={() => handlePageChange(currentPage - 1)}
              aria-label="Previous"
            >
              <span aria-hidden="true">&laquo;</span>
              <span className="sr-only">Previous</span>
            </button>
          </li>

          {renderPageButtons()}

          <li className="page-item">
            <button
              className="page-link"
              disabled={currentPage === totalPages}
              onClick={() => handlePageChange(currentPage + 1)}
              aria-label="Next"
            >
              <span aria-hidden="true">&raquo;</span>
              <span className="sr-only">Next</span>
            </button>
          </li>

          <li className="page-item">
            <button
              className="page-link"
              disabled={currentPage === totalPages}
              onClick={() => handlePageChange(totalPages)}
              aria-label="Last"
            >
              <span aria-hidden="true">Last</span>
            </button>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default PaginationComponent;

import {commonConstants, homeConstants, orderConstants} from "../constants";
import axiosConfig from '../axiosConfig'

const websiteId  = 1;
export const getTest = () => async (dispatch) => {

    try {
        const res = await axiosConfig.get(`/websites/1/websitemaincategories`);
        dispatch({
            type: homeConstants.TEST,
            payload: res.data,
        });
    } catch (e) {
        dispatch({
            type: homeConstants.TEST_ERROR,
            payload: console.log(e),
        });
    }
};

export const getFaq = () => async (dispatch) => {
    try {
        const res = await axiosConfig.get(`/websites/`+websiteId+`/websitefaqs?sort=id`);
        dispatch({
            type: commonConstants.FAQ,
            payload: res.data,
        });
    } catch (e) {
        dispatch({
            type: commonConstants.FAQ_ERROR,
            payload: console.log(e),
        });
    }
};

//page with slug
export const getPage = (slug,type=null) => async (dispatch) => {
    try {
        //websites/1/websitepages/detail?slug=
        let res = null;
        if(type === 'faq'){
             res = await axiosConfig.get(`/websites/`+websiteId+`/websitepages?filter[type]=`+slug);
        }else{
             res = await axiosConfig.get(`/websites/`+websiteId+`/websitepages/detail?url=`+slug);
        }
        dispatch({
            type: commonConstants.PAGE,
            payload: res.data,
        });
    } catch (e) {
        dispatch({
            type: commonConstants.PAGE_ERROR,
            payload: console.log(e),
        });
    }
}


export const getwebsitePage = (slug,type=null) => async (dispatch) => {
    try {
        let res = await axiosConfig.get(`/websites/`+websiteId+`/websitewebpages/detail?url=`+slug);
        dispatch({
            type: commonConstants.WEBSITE_PAGE,
            payload: res.data,
        });
    } catch (e) {
        dispatch({
            type: commonConstants.WEBSITE_PAGE_ERROR,
            payload: console.log(e),
        });
    }
}


export const getReturnpolicies = () => async (dispatch) => {
    try {
        const res = await axiosConfig.get(`/websites/`+websiteId+`/websitereturnpolicies`);
        dispatch({
            type: commonConstants.RETUEN_POLICIES,
            payload: res,
        });
    } catch (e) {
        dispatch({
            type: commonConstants.RETUEN_POLICIES_ERROR,
            payload: console.log(e),
        });
    }
};

export const orderNow = (jsonobj) => async (dispatch) => {
    try {
        const res = await axiosConfig.post(`/websites/${websiteId}/websiteorders`, jsonobj);
        let result = {
            status: res.status,
            order: res,
        };
        dispatch({
            type: orderConstants.ORDER,
            payload: result, // Assuming the response data is what you want to dispatch
        });
    } catch (e) {
        // Dispatch the error action
        dispatch({
            type: orderConstants.ORDER_ERROR,
            payload: e.response.data,
        });
    }
};

export const orderUpdate = (id,jsonobj) => async (dispatch) => {
    try {
        const res = await axiosConfig.patch(`/websites/${websiteId}/websiteorders/`+id, jsonobj);
        let result = {
            status  : res.status,
            product : res,
        };
        dispatch({
            type: orderConstants.ORDER_UPDATE,
            payload: result, // Assuming the response data is what you want to dispatch
        });
    } catch (e) {
        // Dispatch the error action
        dispatch({
            type: orderConstants.ORDER_UPDATE_ERROR,
            payload: e.response.data,
        });
    }
};

//product price check api
export const productPriceCheck = (jsonobj) => async (dispatch) => {
    try {
        const res = await axiosConfig.post(`/websites/${websiteId}/websiteproducts/price-check`, jsonobj);
        dispatch({
            type: orderConstants.ORDER_PRICE_CHECK,
            payload: res, // Assuming the response data is what you want to dispatch
        });
    } catch (e) {
        // Dispatch the error action
        dispatch({
            type: orderConstants.ORDER_PRICE_CHECK_ERROR,
            payload: e.response.data,
        });
    }
}

export const resetOrder = () => ({
    type: orderConstants.RESET_ORDER,
});


export const quoteionStore = (jsonobj) => async (dispatch) => {
    try {
        const res = await axiosConfig.post(`/websites/${websiteId}/websitequotations`, jsonobj);
        let result = {
            status: res.status,
            quote: res.data,
        };
        dispatch({
            type: commonConstants.QUOTE,
            payload: result, // Assuming the response data is what you want to dispatch
        });
    } catch (e) {
        // Dispatch the error action
        dispatch({
            type: commonConstants.QUOTE_ERROR,
            payload: e.response.data,
        });
    }
};


export const countries = () => async (dispatch) => {
    try {
        const res = await axiosConfig.get(`/world/countries`);
        dispatch({
            type: commonConstants.COUNTRIES,
            payload: res,
        });
    } catch (e) {
        dispatch({
            type: commonConstants.COUNTRIES_ERROR,
            payload: console.log(e),
        });
    }
};

export const states = (country) => async (dispatch) => {
    try {
        const res = await axiosConfig.get(`/world/states/${country}`);
        dispatch({
            type: commonConstants.STATES,
            payload: res,
        });
    } catch (e) {
        dispatch({
            type: commonConstants.STATES_ERROR,
            payload: console.log(e),
        });
    }
};

export const city = (country,state) => async (dispatch) => {
    try {
        const res = await axiosConfig.get(`/world/cities/${country}/${state}`);
        dispatch({
            type: commonConstants.CITY,
            payload: res,
        });
    } catch (e) {
        dispatch({
            type: commonConstants.CITY_ERROR,
            payload: console.log(e),
        });
    }
};

export const fedex = (jsonobj) => async (dispatch) => {
    try {
        const res = await axiosConfig.get(`/websites/${websiteId}/websiteshippings/shipping-methods?${jsonobj.toString()}`);
        dispatch({
            type: commonConstants.FEDEX,
            payload: res,
        });
    } catch (e) {
        dispatch({
            type: commonConstants.FEDEX_ERROR,
            payload: console.log(e),
        });
    }
};

export const websitepaymentmethods = () => async (dispatch) => {
    try {
        const res = await axiosConfig.get(`/websites/${websiteId}/websitepaymentmethods?filter[active]=true`);
        // const res = await axiosConfig.get(`/websites/${websiteId}/websitepaymentmethods`);
        dispatch({
            type: commonConstants.PAYMENT_METHOD,
            payload: res,
        });
    } catch (e) {
        dispatch({
            type: commonConstants.PAYMENT_METHOD_ERROR,
            payload: console.log(e),
        });
    }
};

export const cardPayment = (jsonobj) => async (dispatch) => {
    try {
        const res = await axiosConfig.post(`/websites/${websiteId}/websitepayments/card`, jsonobj);
        // let result = {
        //     status: res.success,
        //     payemnt_data: res.data,
        // };
        dispatch({
            type: commonConstants.CARD,
            payload: res, // Assuming the response data is what you want to dispatch
        });
    } catch (e) {
        // Dispatch the error action
        dispatch({
            type: commonConstants.CARD_ERROR,
            payload: e.response.data,
        });
    }
};


export const websiteshippingmethods = () => async (dispatch) => {
    try {
        const res = await axiosConfig.get(`/websites/${websiteId}/websiteshippingmethods?filter[type]=flat,free_ground&filter[active]=true`);
        dispatch({
            type: commonConstants.SHIPPING_METHOD,
            payload: res,
        });
    } catch (e) {
        dispatch({
            type: commonConstants.SHIPPING_METHOD_ERROR,
            payload: console.log(e),
        });
    }
};


export const blogs = (page,per_page) => async (dispatch) => {
    try {
        const res = await axiosConfig.get(`/websites/${websiteId}/websiteblogs?page=${page}&per_page=${per_page}`);
        dispatch({
            type: commonConstants.BLOGS,
            payload: res,
        });
    } catch (e) {
        dispatch({
            type: commonConstants.BLOGS_ERROR,
            payload: console.log(e),
        });
    }
}

export const blogDetail = (url) => async (dispatch) => {
    try {
        const res = await axiosConfig.get(`/websites/${websiteId}/websiteblogs/detail?url=${url}`);
        dispatch({
            type: commonConstants.BLOG_DETAIL,
            payload: res,
        });
    } catch (e) {
        dispatch({
            type: commonConstants.BLOG_DETAIL_ERROR,
            payload: console.log(e),
        });
    }
}

// export const resetPaymentCard = () => ({
//     type: commonConstants.RESET_PAYMENT_CARD,
// });